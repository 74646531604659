//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
		name: 'navigationFooter',
		computed: {
			footer()
			{
				return {
					sponsors: this.$store.state.global.content.sponsors,
					instagramhandle: this.$store.state.global.content.instagramhandle,
					getintouch: this.$store.state.global.content.getintouch,
					signupurl: this.$store.state.global.content.signupurl
					
					};
			},
			menu() {
				return this.$store.state.global.content.navigationFooter;
			}
		},
	}

